<template>
    <div>
        <header-portrate />


    <div class="pa-2 m-2">
        
        <bread-crumbs :items="items"></bread-crumbs>
        <tabs-comp :tabs="tabs" />

        <v-row :style="`margin-top:0;direction:`+lang.dir+`;padding-left:30px;padding-right:30xp;`">
            <v-col cols="12" class="text-end">
                <b-button id="multiply-button" :class="`mt-10 multiply-button-`+lang.lalgin" 
                :style="`width:150px;background:transparent;border:none`" 
                @click="resetfixed()"
                v-b-toggle.add_fixed_assets>
              <div style="width:150px;color:#000" :class="`multiply-button-content-`+lang.lalgin">اضافة اصل ثابت</div></b-button>
            </v-col>
        </v-row>
        <v-simple-table style="padding-left:30px;padding-right:30xp;">
            <thead>
                <tr>
                    <th class="text-center">القسم</th>
                    <th class="text-center">الاصل الثابت</th>
                    <th class="text-center">تاريخ الشراء</th>
                    <th class="text-center">سعر الشراء</th>
                    <th class="text-center">مدة الاهلاك</th>
                    <th class="text-center">العدد</th>
                    <th class="text-center">الاجمالي</th>
                    <th class="text-center">نسبة الاهلاك</th>
                    <th class="text-center">قيمة الاهلاك</th>
                    <th class="text-center">القيمة الحالية</th>
                    <th class="text-center">الاجراء</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(item,index) in paytypesrows" :key="index">
                    <td>{{item.group_name}}</td>
                    <td>{{item.namear}}</td>
                    <td>{{item.purchase_date}}</td>
                    <td>{{item.pprice}}</td>
                    <td>{{item.yers}}</td>
                    <td>{{item.qtty}}</td>
                    <td>{{item.total}}</td>
                    <td>{{item.dep_percentage}}</td>
                    <td>{{item.depamount}}</td>
                    <td>{{item.current}}</td>
                    <td style="width:100px;background:#000;border-bottom:1px solid #fff !important;padding:1px">
                        <b-button class="btn-sm btn-default" style="color:#FFF;background:#000;width:100px;height:25px !important;margin-left:5px;border:none" @click="edit(item)" v-b-toggle.add_fixed_assets>{{lang.update}} &nbsp;	&nbsp;	<i :class="`fas fa-caret-`+lang.lalgin"></i></b-button>
                    </td>
                </tr>
            </tbody>
        </v-simple-table>
        <add-fixed-asstes ref="myfixedassets" />
    </div>
        <Footer />
    </div>
</template>

<script>
import breadCrumbs from '@/components/breadCrumbs.vue'
import TabsComp from '@/components/tabsComp.vue'
import axios from 'axios';
import HeaderPortrate from '@/components/Header-Portrate.vue';
import Footer from '@/components/Footer.vue';
import AddFixedAsstes from '@/components/addFixedAsstes.vue';
export default {
    components: { breadCrumbs, TabsComp, HeaderPortrate,Footer, AddFixedAsstes },
    data() {
        return {
            paytypesrows:[]
        }
    },
    created() {
        this.getFixedAsstes();
    },
    methods: {
        resetfixed(){
            this.$refs.myfixedassets.restAll();
        },
        edit(item){
            this.$refs.myfixedassets.fillit(item);
        },
        getFixedAsstes(){
            const post = new FormData();
            post.append('type','getFixed');
            post.append('auth',this.$cookies.get(this.$store.state.COOKIEPhase));
            post.append('data[id]',"all");
            axios.post(
                this.$store.state.SAMCOTEC.r_path, post
            ).then((response) => {
                const res = response.data;
                // console.log(res);
                this.paytypesrows = res.results.data;
                
            })
        },
    },
    computed: {
        lang: function(){
            if(this.$cookies.get(this.$store.state.LangCooki) != null){
                if(this.$cookies.get(this.$store.state.LangCooki)  == 'en'){
                return this.$store.state.lang.en;
                }else{
                return this.$store.state.lang.ar;
                }
            }
            else if(this.$store.state.deflang == 'en'){
                return this.$store.state.lang.en;
            }else{
                return this.$store.state.lang.ar;
            }
        },
        tabs: function(){
            return [
                {
                    index:0,name:this.lang.chart_of_account,href:'/finance/coa',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.finance_jvs,href:'/finance/jvs',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:'أرصدة افتتاحية',href:'/finance/fient',class: 'mytab2',smclass:'font-small'
                },
                // {
                //     index:1,name:this.lang.exchange_voucher,href:'/finance/outreceipt',class: 'mytab2',smclass:'font-small'
                // },
                {
                    index:0,name:this.lang.paytypes,href:'/finance/paytypes',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.fixed_assets,href:'/finance/fixed-assets',class: 'mytab',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.definition,href:'/finance/definition',class: 'mytab3',smclass:'font-small'
                },
                {
                    index:0,name:this.lang.expenses_summary,href:'/expenses/summary',class: 'mytab4',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.general_expenses,href:'/expenses/general',class: 'mytab2',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.exp_management,href:'/expenses/management',class: 'mytab2',smclass:'font-small'
                },
                {
                    index:1,name:this.lang.recurring_expensess,href:'/expenses/recurring',class: 'mytab3',smclass:'font-small'
                },
            ]
        },
        items: function() {
            return {
                    text: this.lang.paytypes,
                    disabled: true,
                    to: '/finance/paytypes',
                }
        },
       
    },
}
</script>